<template>
  <!-- 소식 페이지 전용 코치카드 -->
  <div news-coach-card>
    <div class="preview-profile-img-wrap">
      <Avatar :info="coachUser" :defaultImgType="'fruit'" class="profile-avatar" dimension="100x100" userProfile />
      <div :class="{online: coachStatus === 'ONLINE', coaching: coachStatus === 'COACHING' }" class="profile-online"></div>
    </div>
    <div class="news-coach-card-content">
      <p class="coach-nickname">{{ nickname }}</p>
      <p class="coach-hint">{{ description }}</p>
      <basic-button tag="router-link" :to="coachRoute" theme="white" size="sm" class="coach-visit">코치님께 1:1 상담받기</basic-button>
    </div>
  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import coach from '@/mixins/coach';

export default {
  name: 'NewsCoachCard',
  components: { BasicButton, Avatar },
  mixins: [coach],
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    coachDetail: {},
  }),
  computed: {
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo;
    },
    coachUser() {
      return this.ci?.user;
    },
    nickname() {
      return this.ci?.user?.nickname ?? '';
    },
    coachStatus() {
      return this.ci?.status;
    },
    coachId() {
      return this.ci?.coachId ?? '';
    },
    // description() { return this.ci?.description ?? ''}
    cd() {
      return /** @type{CoachDetail} */this.coachDetail;
    },
    description() {
      return this.cd?.description ?? '';
    },
    coachRoute() {
      return this.getCoachPageRoute(this.coachId);
    },
  },
  async beforeMount() {
    this.coachDetail = await this.$services.coaching.getTutorDetail(this.coachId);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[news-coach-card] {.p(32);.flex;.flex-row;.noto; border: 1px solid #ebebeb;.br(8);.bgc(#fff);

  // 디자인 요청: CoachRowList 참조
  .preview-profile-img-wrap { .rel; .w(80); .h(80); .br(24); .mr(16 + 4);
    .profile-avatar { .w(80); .h(80); .br(24); border: solid 2px #ebebeb;}
    .profile-online { .abs; .t(68); .wh(16); .br(50%); border: 2px solid #fff; .bgc(#5f5f5f);
      &.online { .bgc(#0ce757); }
      &.coaching { .bgc(#6aa6ff); }
    }
    .skeleton { .w(120); .h(120); .br(32); }
  }

  .news-coach-card-content {flex: 1;.min-w(0);
    > .coach-nickname {.c(#1f2226);.fs(18);font-weight: 700;.lh(26);.mb(4)}
    > .coach-hint {.fs(14);.h(20);font-weight: 400;.c(#a4a3ae);.h(20);.ellipsis;.mb(12)}
    > .coach-visit {display: inline-flex;}
  }

  @media (@tp-down) {
    .p(20);
    .preview-profile-img-wrap { .w(46); .tc; .mr(8 + 4);
      .profile-avatar { .w(46); .h(46); .br(16);}
      .profile-online { .t(39); .l(0);.wh(10); border-width: 1px}
    }
    .news-coach-card-content {
      > .coach-nickname { .fs(16);.lh(23)}
      > .coach-hint {.fs(14);.lh(20);.h(unset);.max-h(60);white-space: normal;.rel;}
      // 말줄임 표기
      > .coach-hint:after {.abs;content: '';.rt(0, 40);display: inline-block;.wh(60, 20);
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
      }
    }
  }
}
</style>
