<template>
  <Modal small-confirm-modal @close="close">
    <template #title>
      <h1>{{ options.title }}</h1>
    </template>
    <div class="btn-wrapper">
      <basic-button theme="white" @click="close">{{ $t('no') }}</basic-button>
      <basic-button theme="dark" @click="confirm">{{ $t('yes') }}</basic-button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'SmallConfirmModal',
  components: { BasicButton, Modal },
  props: {
    options: Object,
    default: () => ({}),
  },
  computed: {
    theme() {
      return this.options?.theme ?? 'base';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('resolve');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[small-confirm-modal] {
  h1 {.fs(20);.lh(26);text-align: center;.mb(40);}
  .btn-wrapper {.flex;.mb(48);}
  .btn-wrapper > [basic-button] {flex: 1;}
  .btn-wrapper > [basic-button] + [basic-button] {.ml(16)}
}
</style>
