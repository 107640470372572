<template>
  <article coach-news-item>
    <div class="news-item-inner">
      <template v-if="isNewsValid">
        <cdn-img :src="thumbnailImageUrl" :alt="thumbnailImageTitle" class="news-item-thumbnail" />
        <div class="title-area">
          <p class="news-category">{{ categoryText }}</p>
          <h1 class="news-title">{{ title }}</h1>
          <p class="news-date">작성일 {{ insertDatetime }}</p>
        </div>
        <markdown theme="coach-news" :source="content" />
        <div class="cta-button-area">
          <basic-button v-if="ctaButtonValid" tag="a" :href="ctaButtonLink" theme="dark" class="cta-button" target="_blank">{{ ctaButtonTitle }}</basic-button>
        </div>
        <div class="button-group">
          <basic-button theme="text-only-dark" tag="router-link" :to="{ name: 'CoachNews' }" size="sm">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 19H21V16.6667H7V19ZM0 5V7.33333H21V5H0ZM7 13.1667H21V10.8333H7V13.1667Z" fill="currentColor" />
            </svg>
            다른소식 보기
          </basic-button>
          <basic-button theme="text-only-dark" @click="shareCoachUrl" size="sm">
            <svg svg-share width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z" fill="currentColor" />
            </svg>
            공유하기
          </basic-button>
        </div>
        <news-coach-card v-for="coach in relatedCoaches" :key="coach.coachId" :coach-info="coach" />
        <client-only>
          <div class="button-group admin-only" v-if="isOrganizer">
            <basic-button @click="onRemove">
              삭제
            </basic-button>
            <basic-button theme="blue" :to="{name: 'CoachNewsItemEdit', params: {newsId}}" tag="router-link">
              수정
            </basic-button>
          </div>
        </client-only>
      </template>
      <template v-else>
        <p>존재하지 않는 소식입니다</p>
      </template>
    </div>
  </article>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import { getter } from 'shared/utils/storeUtils';
import { copyClipboard } from '@shared/utils/commonUtils';
import { cdnSrc } from 'shared/utils/cdnUtils';
import Markdown from '@/views/components/coaching/Markdown.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import NewsCoachCard from '@/views/components/coaching/coach-news/NewsCoachCard.vue';
import ConfirmModal from '@/views/components/common/modal/SmallConfirmModal.vue';

export default {
  name: 'CoachNewsItem',
  components: { NewsCoachCard, BasicButton, Markdown, CdnImg },
  data: () => ({
    /** @type{CoachNews} */
    newsInfo: {},
  }),
  lexicon: 'coaching',
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'title', content: this.title },
        { vmid: 'url', content: this.$t('meta.coachNewsItem.url', { newsId: this.newsId }) },
        { vmid: 'description', content: this.ogDescription },
        { vmid: 'keywords', content: this.ogKeywords },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.metaImage },
      ],
    };
  },
  computed: {
    isOrganizer: getter('auth', 'isOrganizer'),
    newsId() {
      return this.newsInfo?.newsId ?? '';
    },
    // 뉴스 정보가 제대로 불러와졌는지 검사
    isNewsValid() {
      return this.newsId !== '';
    },
    content() {
      return this.newsInfo?.content ?? '';
    },
    thumbnailImageUrl() {
      return this.newsInfo?.thumbnailImageUrl ?? '';
    },
    thumbnailImageTitle() {
      return this.newsInfo?.thumbnailImageTitle ?? '';
    },
    category() {
      return this.newsInfo?.category ?? '';
    },
    categoryText() {
      const key = `coaching.news.category.${this.category}`;
      return this.$te(key) ? this.$t(key) : this.category;
    },
    title() {
      return this.newsInfo?.title ?? '';
    },
    insertDatetime() {
      const insertDatetime = this.newsInfo?.insertDatetime ?? 0;
      return this.$date(insertDatetime, 'dot');
    },
    ogDescription() {
      return this.newsInfo?.ogDescription ?? '';
    },
    ogKeywords() {
      return this.newsInfo?.ogKeywords ?? '';
    },
    metaImage() {
      // full url로 수정 요망
      return cdnSrc(this.thumbnailImageUrl);
    },
    relatedCoaches() {
      return /** @type{Coach} */ this.newsInfo?.relatedCoaches ?? [];
    },
    newsUrl() {
      const host = process.env.VUE_APP_HOME_URL;
      const path = this.$router.resolve({ name: 'CoachNewsItem', params: { newsId: this.newsId } }).href;
      return [host, path].join('');
    },
    ctaButtonLink() {
      return this.newsInfo?.ctaButtonLink ?? '';
    },
    ctaButtonTitle() {
      return this.newsInfo?.ctaButtonTitle ?? '';
    },
    ctaButtonValid() {
      return this.ctaButtonLink !== '' && this.ctaButtonTitle !== '';
    },
  },
  methods: {
    async onRemove() {
      await this.$modal(ConfirmModal, { title: '작성하신 내용을 삭제할까요?' });
      await this.$services.news.removeNews(this.newsId);
      this.$router.replace({ name: 'CoachNews' });
    },
    shareCoachUrl() {
      copyClipboard(this.newsUrl);
      this.$toast('coaching.COACH_URL_COPIED_TO_CLIPBOARD');
    },
  },
  async asyncData({ services, route }) {
    const newsId = route.params.newsId ?? '';
    const newsInfo = await services.news.getNewsDetail(newsId);
    return { newsInfo };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-news-item] { .bgc(#fff);.c(#191919);.noto();
  .news-item-inner {.responsive-w(848);.ml(auto);.mr(auto);.pb(120);}
  .news-item-thumbnail { .w(100%);.max-h(424);}
  .news-category {.c(#a4a3ae);.fs(16);.lh(20);font-weight: 500;}
  .news-title {.fs(24);font-weight: 700;.lh(120%);}
  .title-area > * + * { .mt(12);}
  // 컨텐츠 최상단 영역에서 margin-top을 0 이상으로 줄 경우 페이지의 빈 영역이 드러나게 됨.
  // margin-top 대신 padding-top 활용
  .title-area {.mb(80); text-align: center;.pt(64); }
  .news-date {.mt(20);font-weight: 400;.fs(16);.lh(175%);.c(#444);.h(28);}
  .button-group {.flex;justify-content: flex-end;.h(56);.mb(32);.mt(32)}
  .button-group [basic-button] {
    svg {.mr(7) }
  }
  .button-group.admin-only {.flex-jc(center);
    [basic-button] + [basic-button] {.ml(10)}
  }
  [markdown] {.mb(64)}
  [news-coach-card] + [news-coach-card] {.mt(16);}
  .cta-button-area { text-align: center; }
  .cta-button {display: inline-flex;}
  @media (@tp-down) {
    .news-item-inner {.pt(0);.pb(110)}
    [markdown] {.mb(32);}
  }
  @media (@ml-down) {
    .news-item-inner {.pt(20)}
  }
  @media (max-width: 820px) {
    .news-item-inner {.pl(20);.pr(20)}
  }
}
</style>
