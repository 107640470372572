<script>
import { marked } from 'marked';
import CdnImg from '@shared/components/common/CdnImg.vue';
import VideoEmbed from '@/views/components/coaching/VideoEmbed.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'Markdown',
  components: { VideoEmbed, CdnImg, BasicButton },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    source: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'coach-news',
    },
  },
  computed: {
    marked() {
      const mk = marked;
      // lexer가 제대로 동작하기 위한 기본 option
      mk.use({ gfm: true });
      return mk;
    },
    renderedTokens() {
      try {
        return this.marked.lexer(this.source, {});
      } catch (err) {
        console.error('markdown render token fail', err);
        return [{ type: 'paragraph', text: `마크다운 에러: ${err.toString()}` }];
      }
    },
  },
  methods: {
    parseToken(createEl, token) {
      const parsers = {
        // [elementName: string | Vue.Component, props?: VueProps , children?: [] ]
        heading: token => [`h${token.depth}`],
        paragraph: token => {
          // *A ssr hydrator가 blockquote안에 p를 허용하지 않기 때문에 별도 처리
          if (token.parent === 'blockquote') return ['span'];
          if ((token?.tokens ?? []).some(t => t?.type === 'image')) return ['figure', { attrs: { class: 'with-image' } }];
          return ['p'];
        },
        link: token => {
          // 링크를 확장하여 색상 정의 용도로도 사용
          if (/^@color:.+/.test(token.href)) {
            const color = token.href.replace('@color:', '');
            // https://v2.vuejs.org/v2/guide/render-function.html#The-Data-Object-In-Depth
            // createEl에 대한 Data Object 참조
            return ['span', { style: { color } }];
          }
          // 버튼 형태의 링크는 버튼 컴포넌트를 사용하는 것으로 정의
          // [label](@button:https://google.com)
          if (/^@button:.+/.test(token.href)) {
            const href = token.href.replace('@button:', '');
            return ['basic-button', { props: { tag: 'a', theme: 'dark' }, attrs: { href } }];
          }
          // 버튼 형태가 아닐 때에는 일반 링크 <a href...> 로 전달
          return ['a', { attrs: { href: token.href } }];
        },
        space: () => ['br'],
        list: token => [token.ordered ? 'ol' : 'ul'],
        list_item: () => ['li'],
        text: token => ['text', this.unescape(token.text)],
        strong: () => ['b'],
        image: token => {
          if (token.text === 'youtube') return ['video-embed', { props: { url: token.href, title: token.title, theme: 'no-round' } }];
          // ![alt-text](@cdn:stage/images/test.jpg)
          const isCdn = /^@cdn:.+/.test(token.href);
          const href = isCdn ? token.href.replace('@cdn:', '') : token.href;
          const elImg = [isCdn ? 'cdn-img' : 'img', { [isCdn ? 'props' : 'attrs']: { src: href, alt: token.text } }];
          if (token.title) {
            return ['client-only', [createEl(...elImg), createEl('figcaption', [token.title])]];
          }
          return elImg;
        },
        html: token => {
          if (token.raw === '<br/>' || token.raw === '<br />' || token.raw === '</br>') return ['br'];
          if (token.raw === '<indent/>' || token.raw === '<indent />') return ['span', { attrs: { class: 'indent' } }];
          return ['p', { domProps: { innerHTML: token.text } }];
        },
        em: () => ['em'],
        blockquote: token => {
          // *A 참조
          (token?.tokens ?? []).forEach(t => t.parent = 'blockquote');
          return ['blockquote'];
        },
        unknown: token => ['unknown', JSON.stringify(token)],
      };
      const parsed = parsers?.[token?.type] ? parsers?.[token?.type](token) : parsers.unknown(token);
      // 모든 element는 내부에 최하위 텍스트로 text요소를 가진다.
      if (parsed?.[0] === 'unknown' || parsed?.[0] === 'text') {
        // 목록(ul, ol) 안에 여러 요소를 넣으면 무조건 text로 한 번 감싸진다.
        // 따라서 여러개의 아이템이 있을 수도 있다면 예외적으로 최하위 아이템이 아닌 것으로 보고 살려주어야한다.
        // 대신 자기 자신의 형태(text)는 버린다
        if (token?.tokens) return this.traverseToken(createEl, token?.tokens);
        return parsed[1];
      }
      if (token?.tokens) return createEl(...parsed, this.traverseToken(createEl, token?.tokens));
      if (token?.items) return createEl(...parsed, this.traverseToken(createEl, token?.items));
      return createEl(...parsed);
    },
    traverseToken(createEl, tokens = []) {
      return tokens.map(t => this.parseToken(createEl, t));
    },
    // marked.js의 특수문자 escape 복원
    unescape(text) {
      const unescapeMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '\'',
        '&#39;': `"`,
      };
      return text.replace(/(&.{2,4};)/g, char => unescapeMap[char]);
    },
  },
  render(createEl) {
    const children = this.traverseToken(createEl, this.renderedTokens);
    return createEl(this.tag, { attrs: { 'markdown': 'markdown', 'data-theme': this.theme } }, children);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[markdown] {

  &[data-theme='storybook-debug'] {
    [video-embed] {
      .min-h(350);
    }

    & > * + * {
      .mt(15);
    }

    a {
      text-decoration: underline;
      text-decoration-color: black;
    }
  }
  &[data-theme='coach-news'] {.noto;
    .c(#191919);
    & > * + * {
      .mt(32);
    }
    & > ul, & > ol {
      .ml(auto);.mr(auto);.max-w(700);
    }
    *:not([basic-button]) {word-break: break-all;}
    h1, h2, h3, h4, h5, h6 {;font-weight: 700;.max-w(700);.ml(auto);.mr(auto);
      // 제목 다음 바로 본문 텍스트가 올 경우에는 여백을 줄임
      & + p { .mt(20) }
    }
    h1 {.fs(24);.lh(28.8);}
    p, span, figure, figcaption {.fs(16);.lh(28);}
    p {.max-w(700);.ml(auto);.mr(auto);}
    figure.with-image {.w(100%); text-align: center;
      img { .w(100%)}
      figcaption { text-align: center; }
    }
    [video-embed] {
      .h(450);
    }
    ul {.ul-disc-style;}
    ol {
      > li {.fs(16);.lh(30);.ml(30);list-style-type: numeric;}
      // 코치 상세 페이지의 목록 규칙을 따르고자할 경우 주석 제거
      //@media(@tp-down) {
      //  > li {.fs(14);.lh(24);.ml(21);}
      //}
    }
    em {font-style: italic;}
    blockquote {border-left: solid 5px #ddd;.p(20);.c(#777);.fs(12);}
    // 들여쓰기
    span.indent {.w(25);display: inline-block;}
    a:not([basic-button]) {
      text-decoration: underline;
      text-decoration-color: black;
    }
    @media (@tp-down) {
      // 코치 상세 페이지의 목록 규칙을 따르고자할 경우 아래 내용 삭제
      ul > li {.fs(16);.lh(30)}
    }
  }
}
</style>
